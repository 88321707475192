import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import Layout from '../components/Layout';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import ContactForm from '../components/ContactForm';
import EventRoll from '../components/EventRoll';
import { MarkdownContent } from '../components/Content';

import {
  FaAsterisk,
  FaHandHoldingUsd,
  FaChevronUp,
  FaChevronDown,
} from 'react-icons/fa';
import { MdLocationOn } from 'react-icons/md';
import diversity from '../img/diversity.svg';

function getDiscountIcon(icon, externalImageUrl) {
  if (icon === 'Hand with Dollar Sign') {
    return <FaHandHoldingUsd size="1.5em" />;
  } else if (icon === 'Diversity') {
    return <img src={diversity} />;
  } else if (icon === 'External Image URL') {
    return (
      <PreviewCompatibleImage
        imageInfo={{
          image: externalImageUrl,
          alt: 'external icon for discount',
        }}
      />
    );
  }
}

export const IndexPageTemplate = ({
  backgroundImage,
  hero,
  aboutTheWork,
  offerings,
  megsExperience,
}) => {
  const [megsExperienceExpanded, setMegsExperienceExpanded] = useState(false);
  const [aboutTheWorkExpanded, setAboutTheWorkExpanded] = useState(false);
  const [contactFormMessage, setContactFormMessage] = useState(undefined);

  return (
    <div>
      <section
        id="top"
        className="hero is-green is-fullheight-with-navbar bg-circle-top has-text-white"
      >
        <div className="hero-body is-align-items-stretch">
          <div className="container is-flex is-flex-direction-column is-justify-content-space-around">
            <div className="is-flex is-flex-direction-row">
              <div className="logo-img">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: hero.image,
                    alt: hero.heading,
                  }}
                />
              </div>
              <div>
                <h1 className="title is-1 is-size-2-mobile has-text-white">
                  {hero.heading}
                </h1>
                <h2 className="subtitle is-3 is-size-5-mobile has-text-white">
                  {hero.subheading}
                </h2>
              </div>
            </div>
            <div className="quote-container">
              <blockquote>{hero.quote}</blockquote>
              <cite>{hero.citation}</cite>
            </div>
            <div>
              <AnchorLink
                to={hero.ctaBtnLink}
                title={hero.ctaBtnText}
                className="button mt-3 stripped theme-btn"
                stripHash
              />
            </div>
          </div>
        </div>
      </section>
      <section
        id="about-the-work"
        className="section is-medium bg-circle-bottom"
      >
        <div className="container">
          <div className="columns">
            <div className="column is-full">
              <h1 className="title is-2 is-size-3-mobile">
                {aboutTheWork.heading}
              </h1>
            </div>
          </div>
          <div className="columns">
            <div className="column is-half">
              <MarkdownContent content={aboutTheWork.content} />
              {aboutTheWorkExpanded && (
                <MarkdownContent
                  content={aboutTheWork.contentExpanded}
                  className="mt-5"
                />
              )}
              <div>
                <div className="field has-addons mt-5">
                  <button
                    className="button is-flex is-justify-content-space-around"
                    onClick={() =>
                      setAboutTheWorkExpanded(!aboutTheWorkExpanded)
                    }
                  >
                    <span>{aboutTheWork.ctaBtnText}</span>
                    {'  '}
                    {aboutTheWorkExpanded ? (
                      <FaChevronUp className="ml-2" />
                    ) : (
                      <FaChevronDown className="ml-2" />
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className="column is-half">
              <PreviewCompatibleImage
                imageInfo={{
                  image: aboutTheWork.image,
                  alt: aboutTheWork.heading,
                }}
              />
            </div>
          </div>
        </div>
      </section>
      <section id="offerings" className="section has-text-white is-green">
        <div className="container">
          <div className="columns">
            <div className="column is-full has-text-white">
              <h1 className="title is-2 is-size-3-mobile has-text-white">
                {offerings.heading}
              </h1>
              <p>{offerings.content}</p>
            </div>
          </div>
          <div className="tags are-medium" style={{ overflow: 'auto' }}>
            {offerings.items.map((item) => (
              <div className="tag">{item.heading}</div>
            ))}
          </div>
          <div className="mt-6">
            <h2 className="title is-4 has-text-white">
              {offerings.howItWorks.heading}
            </h2>
            <MarkdownContent content={offerings.howItWorks.content} />
            {offerings.howItWorks.discounts.map((discount) => (
              <div className="box is-light is-size-7 px-3 py-1 mb-3 mt-4">
                <span className="icon-text">
                  <span className="icon mr-2">
                    {getDiscountIcon(discount.icon, discount.externalImageUrl)}
                  </span>
                  {discount.text}
                </span>
              </div>
            ))}
          </div>
          <div className="mt-6">
            <h2 className="title is-4 has-text-white">Locations</h2>
            <div className="columns">
              <div className="column is-one-third">
                {offerings.location.locations.map((location) => (
                  <>
                    <p>
                      <span className="icon-text">
                        <span className="icon mr-2">
                          <MdLocationOn size="1.5em" />
                        </span>
                        {location.text}
                      </span>
                    </p>
                    <br />
                  </>
                ))}
              </div>
              <div className="column is-two-thirds">
                {offerings.location.content}
              </div>
            </div>
          </div>
          <div className="mt-6">
            <h2 className="title is-4 has-text-white">
              {offerings.pricing.heading}
            </h2>
            <div className="pricing-table">
              {offerings.pricing.products.map((product) => (
                <div className="pricing-plan">
                  <div className="plan-header">{product.heading}</div>
                  <div className="plan-price">
                    <span className="plan-price-amount">
                      <span className="plan-price-currency">$</span>
                      {product.price}
                    </span>
                  </div>
                  <div className="plan-items">
                    {product.features?.map((feature) => (
                      <div className="plan-item">{feature?.text}</div>
                    ))}
                  </div>
                  <div className="plan-footer">
                    <AnchorLink
                      to="/#contact"
                      title={product.btnText || 'Choose'}
                      className="button stripped is-fullwidth"
                      stripHash
                      onAnchorLinkClick={() =>
                        setContactFormMessage(
                          `I'm interested in the ${product.heading}.`
                        )
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
            {offerings.pricing.notes.map((note) => (
              <div className="box is-light is-size-7 px-3 py-1 mb-3 mt-4 mx-2">
                <span className="icon-text">
                  <span className="icon mr-2">
                    <FaAsterisk size="1.5em" />
                  </span>
                  {note?.text}
                </span>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section
        id="megs-experience"
        className="section is-parallax"
        style={{
          backgroundImage: `url(${
            !!backgroundImage.childImageSharp
              ? backgroundImage.childImageSharp.fluid.src
              : backgroundImage
          })`,
        }}
      >
        <div className="container box">
          <div className="columns">
            <div className="column is-full">
              <h1 className="title is-2 is-size-3-mobile">
                {megsExperience.heading}
              </h1>
            </div>
          </div>
          <div className="columns">
            <div className="column is-one-fourth">
              <figure className="rounded-corners-img">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: megsExperience.image,
                    alt: megsExperience.heading,
                  }}
                />
              </figure>
              <div>
                <div className="field has-addons mt-3 is-fullwidth">
                  <button
                    className="button is-fullwidth is-flex is-justify-content-space-around"
                    onClick={() =>
                      setMegsExperienceExpanded(!megsExperienceExpanded)
                    }
                  >
                    <span>{megsExperience.ctaBtnText}</span>
                    {'  '}
                    {megsExperienceExpanded ? (
                      <FaChevronUp />
                    ) : (
                      <FaChevronDown />
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className="column is-three-quarters">
              <MarkdownContent content={megsExperience.content} />
              {megsExperienceExpanded && (
                <MarkdownContent
                  content={megsExperience.contentExpanded}
                  className="mt-5"
                />
              )}
            </div>
          </div>
        </div>
      </section>
      <section
        id="classes-workshops-trainings"
        className="section has-text-white is-green"
      >
        <div className="container">
          <h1 className="title is-2 is-size-3-mobile has-text-white">
            Classes, Workshops, and Trainings
          </h1>
          <p className="mb-5">Featured Events:</p>
          <EventRoll featuredEventsOnly={true} />
          <AnchorLink
            to="/events"
            title="See all events"
            className="button is-small mt-4 stripped"
            stripHash
          />
        </div>
      </section>
      <section id="contact" className="section">
        <ContactForm message={contactFormMessage} />
      </section>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  backgroundImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  hero: PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    heading: PropTypes.string,
    subheading: PropTypes.string,
    ctaBtnText: PropTypes.string,
    ctaBtnLink: PropTypes.string,
    quote: PropTypes.string,
    citation: PropTypes.string,
  }),
  aboutTheWork: PropTypes.shape({
    heading: PropTypes.string,
    content: PropTypes.string,
    contentExpanded: PropTypes.string,
    ctaBtnText: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  offerings: PropTypes.shape({
    heading: PropTypes.string,
    content: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string,
        subheading: PropTypes.string,
      })
    ),
    howItWorks: PropTypes.shape({
      heading: PropTypes.string,
      content: PropTypes.string,
      discounts: PropTypes.arrayOf(
        PropTypes.shape({
          icon: PropTypes.string,
          text: PropTypes.string,
          externalImageUrl: PropTypes.string,
        })
      ),
    }),
    location: PropTypes.shape({
      content: PropTypes.string,
      locations: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
        })
      ),
    }),
    pricing: PropTypes.shape({
      heading: PropTypes.string,
      products: PropTypes.arrayOf(
        PropTypes.shape({
          heading: PropTypes.string,
          price: PropTypes.string,
          features: PropTypes.arrayOf(
            PropTypes.shape({
              text: PropTypes.string,
            })
          ),
          btnText: PropTypes.string,
        })
      ),
      notes: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
        })
      ),
    }),
  }),
  megsExperience: PropTypes.shape({
    heading: PropTypes.string,
    content: PropTypes.string,
    contentExpanded: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    ctaBtnText: PropTypes.string,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        backgroundImage={frontmatter.backgroundImage}
        hero={frontmatter.hero}
        aboutTheWork={frontmatter.aboutTheWork}
        offerings={frontmatter.offerings}
        megsExperience={frontmatter.megsExperience}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        backgroundImage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        hero {
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          heading
          subheading
          ctaBtnText
          ctaBtnLink
          quote
          citation
        }
        aboutTheWork {
          heading
          content
          contentExpanded
          ctaBtnText
          image {
            childImageSharp {
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        offerings {
          heading
          content
          items {
            heading
          }
          howItWorks {
            heading
            content
            discounts {
              icon
              text
              externalImageUrl
            }
          }
          location {
            content
            locations {
              text
            }
          }
          pricing {
            heading
            products {
              heading
              price
              features {
                text
              }
              btnText
            }
            notes {
              text
            }
          }
        }
        megsExperience {
          heading
          content
          contentExpanded
          image {
            childImageSharp {
              fluid(maxWidth: 800, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          ctaBtnText
        }
      }
    }
  }
`;
